import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../pages/login/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from './alert/alert.component';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, public dialog: MatDialog, private _snackBar: MatSnackBar
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));

            if (err.status === 401 || err.status === 0) {
                this.authenticationService.logout();
                this.dialog.closeAll();
            }

            let msg;
            const error = err;
            if (err.status === 0) {
                msg = 'No hay conexion con el servidor';
            }
            else {
                msg = error.error.Message || error.error;
            }

            const dialogRef = this.dialog.open(AlertComponent, {
                width: '350px',
                data: {
                    msg: msg,
                    title: 'Error!'
                }
            });

            return throwError(error);
        }))
    }
}