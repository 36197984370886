import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.url;
  private currentUserSubject;
  public currentUser;
  origin: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentUserSubject = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUser = this.currentUserSubject;
  }

  login(loginForm) {
    return this.http.post<any>(this.apiUrl + 'api/Login/authenticate', loginForm)
      .pipe(map((res: Response) => {
        const data = JSON.parse(JSON.stringify(res));
        if (data && data.access_token) {
          localStorage.setItem('currentUser', JSON.stringify(data));
          localStorage.setItem('origin', 'login');
        }
      }, (err) => {
        console.log(err);
      }
      ));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/menu']);
  }
}
