import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { SecretariaGuard } from './_guards/secretaria.guard';
import { TokenGuard } from './_guards/token.guard';
import { BuzonGuard } from './_guards/buzon.guard';

const routes: Routes = [
  {
    path: 'buzon/login',
    loadChildren: () => import('./pages/buzon-electronico/buzon-login/buzon-login.module').then(m => m.BuzonLoginModule),
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule),
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'acuerdos/publicos',
    loadChildren: () => import('./pages/lista-acuerdos-public/lista-acuerdos-public.module').then(m => m.ListaAcuerdosPublicModule)
  },
  { path: "", redirectTo: 'buzon/tabla', pathMatch: "full", canActivate: [BuzonGuard] },
  // { path: "**", redirectTo: 'buzon/tabla', pathMatch: "full", canActivate: [BuzonGuard] },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [BuzonGuard],
    children: [
      {
        path: 'buzon/tabla',
        loadChildren: () => import('./pages/buzon-electronico/buzon-tabla/buzon-tabla.module').then(m => m.BuzonTablaModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
